import React from "react";
import { Menu, MenuContent, MenuItem, MenuLabel, NamedLink } from "../../../components";
import css from './TopbarDesktop/TopbarDesktop.module.css';
import { stringify } from "query-string";
const EquipmentTypesMenu = (props) => {
    const {customClass} = props
    const equipmentTypes = [
      { name: 'Trailers', link: 'SearchPage', params: { pub_categoryLevel1: 'Trailers-id' } },
      { name: 'Box Trucks', link: 'SearchPage', params: { pub_categoryLevel1: 'Boxtrucks-id' } },
      { name: 'Cargo Vans', link: 'SearchPage', params: { pub_categoryLevel1: 'cargovans-id' } },
    ];
  
    return (
      <Menu className={customClass}>
        <MenuLabel className={css.equipmentMenuLabel} isOpenClassName={css.equipmentMenuIsOpen}>
          <span className={css.topbarLinkLabel}>Equipment Types</span>
        </MenuLabel>
        <MenuContent className={css.equipmentMenuContent}>
          {equipmentTypes.map(type => (
            <MenuItem key={type.name}>
              <NamedLink className={css.menuLink} name={type.link} to={{ search: stringify(type.params) }}>
                {type.name}
              </NamedLink>
            </MenuItem>
          ))}
        </MenuContent>
      </Menu>
    );
  };

  export default EquipmentTypesMenu